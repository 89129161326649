::-webkit-scrollbar {
    width: 8px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 1px 1px 1px #f8f8f8;
    border-radius: 1px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #c1c1c1bf;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

@keyframes icon-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
